import Title, { TitleProps } from "antd/lib/typography/Title";
import React from "react";
import "./App.css";
import _bookmarks from "./bookmarks.json";

interface FirefoxBookmarkItem {
  title: string;
  uri?: string;
  children?: FirefoxBookmarkItem[];
}

const bookmarks: FirefoxBookmarkItem = _bookmarks;

const Bookmark = (props: { label: string; href: string }) => {
  return <a href={props.href}>{props.label}</a>;
};

const BookmarkDirectory = (props: FirefoxBookmarkItem & { level: number }) => {
  return (
    <div
      style={{
        paddingLeft: props.level === 1 ? 0 : "10px",
      }}
    >
      <Title level={props.level as TitleProps["level"]}>{props.title}</Title>
      {props.children && (
        <ul>
          {props.children
            .filter((c) => c.uri)
            .map((c) => (
              <li>
                <a href={c.uri}>{c.title}</a>
              </li>
            ))}
        </ul>
      )}
      {props.children && (
        <div>
          {props.children
            .filter((c) => !!c.children)
            .map((c) => (
              <BookmarkDirectory {...c} level={props.level + 1} />
            ))}
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <BookmarkDirectory {...bookmarks} level={1}></BookmarkDirectory>
    </div>
  );
}

export default App;
